import React from "react";
import Svg from "../Svg";
import { SvgProps } from "types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 29 29" {...props}>
      <circle cx="14.1426" cy="14.1421" r="10" transform="rotate(45 14.1426 14.1421)" fill="white" fillOpacity="0.3"/>
      <path d="M7.36953 14.1502C7.36953 15.0502 8.08953 15.7702 9.16953 15.7702L12.6995 15.7702L12.6995 19.3002C12.6995 20.3802 13.4195 21.1002 14.3195 21.1002C15.2195 21.1002 15.9395 20.3802 15.9395 19.3002L15.9395 15.7702L19.4695 15.7702C20.5495 15.7702 21.2695 15.0502 21.2695 14.1502C21.2695 13.2502 20.5495 12.5302 19.4695 12.5302L15.9395 12.5302L15.9395 9.0002C15.9395 7.9202 15.2195 7.2002 14.3195 7.2002C13.4195 7.2002 12.6995 7.9202 12.6995 9.0002L12.6995 12.5302L9.16953 12.5302C8.08953 12.5302 7.36953 13.2502 7.36953 14.1502Z" fill="white"/>
    </Svg>
  );
};

export default Icon;
