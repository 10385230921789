import React from "react";
import Svg from "../Svg";
import { SvgProps } from "types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path fillRule="evenodd" clipRule="evenodd"
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.7285 13.3115C19.2047 13.047 19.5 12.545 19.5 12.0003C19.5 11.4555 19.2047 10.9536 18.7285 10.689L9.72846 5.68903C9.00429 5.28672 8.09108 5.54763 7.68876 6.2718C7.28644 6.99599 7.54736 7.90919 8.27154 8.31151L14.9113 12.0003L8.27154 15.689C7.54736 16.0914 7.28644 17.0046 7.68876 17.7287C8.09108 18.4529 9.00429 18.7138 9.72846 18.3115L18.7285 13.3115Z"
      fill="#ACF800"/>
    </Svg>
  );
};

export default Icon;
